.classDetail .searchbox {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.classDetail .itemLabel {
  margin: 0 20px;
}
.classDetail .df {
  margin-left: 20px;
}
